<template>
  <div>
    <b-row>
      <b-col>
        <ReportsFilters
          :filters="form.filters"
          :csv-data="csv"
          :csvFields="csvFields"
          :admin="admin"
          @filtersChanged="onReportFiltersChanged"
        />
      </b-col>
    </b-row>
    <Alert
      v-if="!items.length && errorMessage"
      id="no-raffle-sales-recorded-alert"
      class="mt-2"
      icon="info"
      variant="yellow"
    >
      No goldrush sales have been recorded yet. Please check back later.
    </Alert>
    <b-row class="overflow-x-hidden" v-else>
      <b-col>
        <div class="table-overflow">
          <b-table
            show-empty
            id="gr-sales-table"
            class="orders-table"
            striped
            sort-icon-right
            :fields="table.fields"
            :per-page="table.perPage"
            :current-page="table.currentPage"
            :items="items"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
            empty-text="test"
          >
            <template #empty>
              <div class="text-left">There are no records to show.</div>
            </template>
            <template v-slot:table-busy>
              <div class="text-left">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
            <template v-slot:cell(eventStatus)="row">
              <EventStatusBadge :status="row.item.eventStatus" />
            </template>
            <template v-slot:cell(eventStartDate)="row">
              <DateTooltip :date="row.item.eventStartDate" />
            </template>
            <template v-slot:cell(eventEndDate)="row">
              <DateTooltip :date="row.item.eventEndDate" />
            </template>
            <template v-slot:cell(show_details)="row">
              <b-button @click="row.toggleDetails" variant="outline-secondary" size="sm">
                {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
              </b-button>
            </template>
            <template v-slot:row-details="row">
              <GoldrushSalesByStationsReport :eventId="row.item.eventId" :drawNumber="row.item.eventDrawNumber" />
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        Total Records:
        <strong>{{ table.rows }}</strong>
      </b-col>
      <b-col cols="6 text-right">
        Last Refreshed:
        <strong v-if="lastRefreshed">{{ formatDateTime(lastRefreshed, { format: 'dateTime' }) }}</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-model="table.currentPage"
          :total-rows="table.rows"
          :per-page="table.perPage"
          aria-controls="table"
          pills
          align="center"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { subDays } from 'date-fns';
import ReportServiceV2 from '@/lib/report-service-v2';
import ReportsFilters from '@/components/reports/ReportsFilters';
import GoldrushSalesByStationsReport from '@/components/reports/GoldrushSalesByStationsReport';
import EventStatusBadge from '@/components/EventStatusBadge';
import DateTooltip from '@/components/DateTooltip';
import Alert from '@/components/ui/Alert';
import store from '@/store';

const dateTimeFormatOptions = {
  format: 'iso8601',
  timeZone: store.state.sessionUser.timeZone,
  displayTimeZone: true
};

export default {
  components: { GoldrushSalesByStationsReport, EventStatusBadge, DateTooltip, ReportsFilters, Alert },
  props: ['eventId', 'organizationId', 'goldrush', 'admin'],
  data() {
    return {
      csv: null,
      lastRefreshed: null,
      errorMessage: false,
      form: {
        filters: {
          refunds: true,
          status: 'closed',
          event: null,
          organization: null,
          startDate: subDays(new Date(), 30),
          endDate: null,
          station: null,
          province: null
        }
      },
      table: {
        isBusy: true,
        rows: 0,
        currentPage: 1,
        perPage: 10,
        sortBy: 'eventEndDate',
        sortDesc: true,
        fields: [
          { label: 'Organization Name', key: 'organizationName', sortable: true, thClass: 'th-large' },
          { label: 'Start Date', key: 'eventStartDate', sortable: true, thClass: 'th-regular' },
          { label: 'End Date', key: 'eventEndDate', sortable: true, thClass: 'th-regular' },
          {
            label: 'Draw',
            key: 'eventDrawNumber',
            thClass: 'text-right th-slim',
            tdClass: 'text-right',
            sortable: true
          },
          { label: 'Status', key: 'eventStatus', sortable: true, thClass: 'text-right th-slim', tdClass: 'text-right' },
          {
            label: 'Current Sales',
            key: 'totalCurrentSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Future Sales',
            key: 'totalFutureSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'Total Sales',
            key: 'totalSalesCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: true,
            formatter: this.formatCurrencyWithDash
          },
          {
            label: 'RB Fee %',
            key: 'raffleboxFeePercent',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: false,
            formatter: function (val) {
              return val + '%';
            }
          },
          {
            label: 'RB Fee',
            key: 'raffleboxFeeCents',
            thClass: 'text-right th-regular',
            tdClass: 'text-right',
            sortable: false,
            formatter: this.formatCurrencyWithDash
          }
        ]
      },
      csvFields: {
        'Organization Name': {
          field: 'organizationName',
          callback: (value) => {
            return `"${value}"`;
          }
        },
        'Start Date': {
          field: 'eventStartDate',
          callback: (value) => {
            if (value) {
              return this.formatDateTime(this.parseISO(value), dateTimeFormatOptions);
            }
          }
        },
        'End Date': {
          field: 'eventEndDate',
          callback: (value) => {
            if (value) {
              return this.formatDateTime(this.parseISO(value), dateTimeFormatOptions);
            }
          }
        },
        Draw: {
          field: 'eventDrawNumber',
          callback: (value) => {
            return Number(value);
          }
        },
        Status: 'eventStatus',
        Orders: {
          field: 'numOrders',
          callback: (value) => {
            return Number(value);
          }
        },
        'Gross Sales': {
          field: 'totalSalesCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'Future Sales': {
          field: 'futureSalesCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        Refunds: {
          field: 'numRefunds',
          callback: (value) => {
            return Number(value);
          }
        },
        'Refunds Total': {
          field: 'totalRefundCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        Raised: {
          field: 'totalRaisedCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'RB Fee %': {
          field: 'raffleboxFeePercent',
          callback: (value) => {
            return Number(value);
          }
        },
        'RB Fee': {
          field: 'raffleboxFeeCents',
          callback: (value) => {
            return Number(value / 100);
          }
        },
        'Net Sales': {
          field: 'totalNetAmountCents',
          callback: (value) => {
            return Number(value);
          }
        }
      }
    };
  },
  watch: {
    'table.sortBy': function () {
      this.refreshTable();
    }
  },
  methods: {
    onReportFiltersChanged(filters) {
      this.form.filters = {
        ...this.form.filters,
        ...filters
      };

      this.refreshTable();
    },
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'gr-sales-table');
    },
    async items() {
      try {
        this.table.isBusy = true;
        this.csv = null;

        const organizationId = this.admin
          ? this.form.filters.organization
            ? this.form.filters.organization
            : undefined
          : this.organizationId;

        const eventStatus = this.form.filters.status ? this.form.filters.status : null;
        const eventProvince = this.form.filters.province ? this.form.filters.province : undefined;

        const params = {
          eventId: this.form.filters.event?.id ? this.form.filters.event?.id : this.eventId,
          eventProvince,
          organizationId,
          fromDate: this.form.filters.startDate || undefined,
          toDate: this.form.filters.endDate || undefined,
          page: this.table.currentPage - 1 || 0,
          pageSize: 10,
          sortBy: this.table.sortBy || 'endDate',
          sortDir: this.table.sortDesc === true ? 'desc' : 'asc',
          eventStatus
        };

        const result = await ReportServiceV2.goldrushSales(params);

        this.table.rows = result.total;
        this.csv = result.data;
        this.lastRefreshed = this.parseISO(result.data[0].lastRefreshed);

        return result.data;
      } catch (error) {
        this.errorMessage = true;
      } finally {
        this.table.isBusy = false;
      }
    }
  },
  async mounted() {
    this.refreshTable();

    // hide the org name if we are not displaying all orgs
    if (this.organizationId) {
      const row = this.table.fields.find((r) => {
        return r.key === 'organizationName';
      });
      row.thClass = 'd-none';
      row.tdClass = 'd-none';
    }
  },
  created() {
    if (!this.admin) {
      delete this.csvFields['Organization Name'];
    }
  }
};
</script>
<style>
.dashline {
  border-bottom: 1px dashed;
}

.b-table-details .table {
  width: auto;
  max-width: 100%;
  min-width: 50%;
}
</style>
