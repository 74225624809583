<template>
  <b-container class="mb-7">
    <Alert v-if="errorMessage" variant="red" icon="exclamation">
      {{ errorMessage }}
    </Alert>

    <b-tabs pills vertical content-class="responsive">
      <b-tab title="Raffle Sales Old" lazy v-if="showRaffleSalesOld">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Reports ></span>
              Raffle Sales Old
            </h1>
          </b-col>
        </b-row>
        <RaffleSalesReportTable :organizationId="organizationId" :admin="false" @error="onError" />
      </b-tab>
      <b-tab active title="Raffle Sales" v-if="showRaffleSalesNew" lazy>
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Reports ></span>
              Raffle Sales
            </h1>
          </b-col>
        </b-row>
        <RaffleSalesReport />
      </b-tab>
      <b-tab title="Goldrush Sales" lazy v-if="showGoldrushSales">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Reports ></span>
              Goldrush Sales
            </h1>
          </b-col>
        </b-row>
        <GoldrushSalesReportTable :organizationId="organizationId" :admin="false" @error="onError" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import RaffleSalesReportTable from '@/components/reports/RaffleSalesReportTable';
import RaffleSalesReport from '@/components/reports/RaffleSalesReport';
import GoldrushSalesReportTable from '@/components/reports/GoldrushSalesReportTable';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    Alert,
    RaffleSalesReportTable,
    RaffleSalesReport,
    GoldrushSalesReportTable
  },
  data() {
    return {
      organizationId: null,
      errorMessage: '',
      showGoldrushSales: false,
      showRaffleSalesNew: false,
      showRaffleSalesOld: false
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    this.organizationId = sessionUser.organizationUuid;
  },
  async mounted() {
    this.showRaffleSalesNew = await featureEnabled(LaunchDarklyKeys.RaffleSalesNew);
    this.showRaffleSalesOld = await featureEnabled(LaunchDarklyKeys.RaffleSalesOld);
    this.showGoldrushSales = await featureEnabled(LaunchDarklyKeys.GoldrushSales);
  },
  methods: {
    onError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  }
};
</script>

<style>
.container {
  max-width: 90%;
  margin-left: 115px;
}

.hide-overflow {
  overflow-x: hidden;
}

.responsive {
  width: calc(100% - 320px);
}
</style>
