<template>
  <div>
    <RbBadge variant="success" label="Active" v-if="status === 'active'" />
    <RbBadge variant="danger" label="InActive" v-if="status === 'inactive'" />
    <RbBadge variant="default" label="Draft" v-if="status === 'pending'" />
    <RbBadge variant="secondary" label="Ended" v-if="status === 'ended'" />
    <RbBadge variant="info" label="Closed" v-if="status === 'closed'" />
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  }
};
</script>
