<template>
  <div class="max-w-full overflow-x-scroll">
    <Alert
      v-if="!loading && !(salesReport && salesReport.length)"
      id="no-raffle-sales-recorded-alert"
      class="mt-2"
      icon="info"
      variant="yellow"
    >
      No raffle sales have been recorded yet. Please check back later.
    </Alert>
    <table class="min-w-full divide-y divide-gray-300" v-else>
      <caption class="sr-only">
        Raffle Sales Report
      </caption>
      <thead class="bg-gray-100">
        <tr>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('id')">
              Raffle #
              <span :class="sortClasses('id')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('id')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-left text-xs tracking-wide text-gray-900 w-44">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('name')">
              Raffle Name
              <span :class="sortClasses('name')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('name')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('startDate')">
              Start Date
              <span :class="sortClasses('startDate')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('startDate')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('endDate')">
              End Date
              <span :class="sortClasses('endDate')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('endDate')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Status
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('category')">
              Category
              <span :class="sortClasses('category')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('category')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Qty Refunds
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Total Refunds
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Qty Orders
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Total Sales
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            <a href="#" class="group inline-flex text-gray-900 whitespace-nowrap" @click="sort('jackpotCents')">
              Jackpot
              <span :class="sortClasses('jackpotCents')">
                <FontAwesomeIcon class="h-3" :icon="['far', sortIcon('jackpotCents')]" />
              </span>
            </a>
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Processing Fees
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            50/50 Payout
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            RB Fee %
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Total Platform Fees
          </th>
          <th scope="col" class="whitespace-nowrap px-3 py-3 text-right text-xs tracking-wide text-gray-900 w-24">
            Total Net Sales
          </th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
        <tr class="justify-content-center" v-if="loading">
          <td colspan="16" class="p-4">
            <RbLoadingSpinner class="m-auto" />
          </td>
        </tr>
        <tr v-for="event in salesReport" :key="event.eventName" v-else>
          <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            {{ event.postgresEventId }}
          </td>
          <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <router-link :to="{ path: '/raffle', query: { id: event.postgresEventUuid } }">
              <span>{{ event.eventName }}</span>
            </router-link>
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            <DateTooltip :date="event.startDate" />
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            <DateTooltip :date="event.endDate" />
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            <EventStatusBadge :status="event.status" />
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800 capitalize">
            {{ formatCategory(event.category) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ event.refunds.totalQuantity }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.refunds.totalCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ event.totals.totalQuantity }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.totals.totalCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.totals.jackpotPercentCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.processingFees.totalCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.winnerPrizes.totalCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ event.platformFees.raffleboxFeePercent }}%
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.platformFees.totalCents / 100) }}
          </td>
          <td class="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-800">
            {{ formatCurrency(event.netSalesCents / 100) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import DateTooltip from '@/components/DateTooltip';
import EventStatusBadge from '@/components/EventStatusBadge';
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  components: {
    Alert,
    DateTooltip,
    EventStatusBadge
  },
  computed: {
    salesReport() {
      return this.$store.state.raffleSalesReport.report;
    },
    loading() {
      return this.$store.state.raffleSalesReport.loading;
    },
    sortBy() {
      return this.$store.state.raffleSalesReport.filters.sortBy;
    },
    sortDir() {
      return this.$store.state.raffleSalesReport.filters.sortDir;
    }
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.$store.commit('SET_FILTERS', { organizationId: sessionUser.organizationUuid });
    await this.getReport();
  },
  methods: {
    async getReport() {
      await this.$store.dispatch('getRaffleSalesReport');
    },
    async sort(column) {
      this.$store.commit('SET_FILTERS', { sortBy: column, sortDir: this.sortDir === 'asc' ? 'desc' : 'asc' });
      await this.getReport();
    },
    sortIcon(column) {
      if (this.sortBy !== column) return 'chevron-down';
      return this.sortDir === 'desc' ? 'chevron-down' : 'chevron-up';
    },
    sortClasses(column) {
      if (this.sortBy === column) {
        return 'ml-2 flex align-items-center px-1 rounded bg-gray-200 group-hover:bg-gray-300';
      } else {
        return 'invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible';
      }
    },
    formatCategory(category) {
      if (category === 'FIFTYFIFTY') {
        return '50/50';
      } else if (category === 'PROMO') {
        return 'Promo Raffle';
      } else {
        return category.toLowerCase();
      }
    }
  }
};
</script>
