<template>
  <LoadingButton variant="outline" @onClick="$emit('onClick')" :loading="downloadingReport">
    <FontAwesomeIcon class="text-sm" :icon="['far', 'arrow-down-to-line']" v-if="!downloadingReport" />
    <p class="mb-0 ml-4 xs:hidden text-sm sm:block">Download</p>
  </LoadingButton>
</template>

<script>
import LoadingButton from '@/components/ui/LoadingButton';

export default {
  name: 'DownloadReportButton',
  components: {
    LoadingButton
  },
  props: {
    downloadingReport: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>
